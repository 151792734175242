import React from 'react';
import { FormikProps } from 'formik';
import { Form, Card, CardHeader, CardBody } from 'reactstrap';
import { range } from 'lodash';

import { SaveButton } from 'components';
import { ISettings } from 'types';

import {
  FeaturesCard,
  NumberSelect,
  PasswordRequirementsCard,
  PaymentsCard,
  ResidentialCustomersCard,
  StatusCard,
} from './components';

export const SettingsForm = (props: ISettings & FormikProps<ISettings>) => {
  const {
    handleSubmit,
    isSubmitting,
    setFieldValue,
    handleChange,
    values: { status, features, passwordRequirements, cache, payments, creditCheck },
  } = props;

  console.log(features, 'features');

  return (
    <Form>
      <StatusCard handleChange={handleChange} status={status} />

      <FeaturesCard handleChange={handleChange} features={features} setFieldValue={setFieldValue} />

      <PasswordRequirementsCard
        handleChange={handleChange}
        passwordRequirements={passwordRequirements}
        setFieldValue={setFieldValue}
      />

      <Card className="mb-3">
        <CardHeader tag="h5">Cache</CardHeader>
        <CardBody>
          <NumberSelect
            value={cache.defaultShardCacheTimeInMinutes}
            field="cache.defaultShardCacheTimeInMinutes"
            onChange={props.handleChange}
            options={range(0, 3800, 200)}
          />
        </CardBody>
      </Card>

      <PaymentsCard features={features} handleChange={handleChange} payments={payments} setFieldValue={setFieldValue} />

      <ResidentialCustomersCard creditCheck={creditCheck} handleChange={handleChange} setFieldValue={setFieldValue} />

      <SaveButton disabled={isSubmitting} onSubmit={() => handleSubmit()} text={isSubmitting ? 'Saving...' : 'Save'} />
    </Form>
  );
};
